export const ALIGNMENTS = ["left", "center", "right", "justify"];

export const LANGUAGES = [
  {
    code: "sk",
    name: "Slovensky",
  },
  {
    code: "cz",
    name: "Česky",
  },
  {
    code: "en",
    name: "English",
  },
  {
    code: "de",
    name: "Deutsch",
  },
];

export const DSOK_LANGUAGES = [
  {
    code: "sk",
    name: "Slovensky",
  },
  {
    code: "en",
    name: "English",
  },
  {
    code: "cs",
    name: "Česky",
  },
  {
    code: "de",
    name: "Deutsch",
  },
];
export const CURRENCY_CODE_POSITIONS = [
  {
    code: "left",
    name: "Left",
  },
  {
    code: "right",
    name: "Right",
  },
];
export const FRONTEND_TYPE = [
  {
    code: "gastrokiosk",
    name: "Gastrokiosk",
  },
  {
    code: "easyPay",
    name: "EasyPay",
  },
];
export const BAR_CODE_TYPES = [
  {
    code: "",
    name: "None",
  },
  {
    code: "EAN13",
    name: "EAN13",
  },
  {
    code: "EAN8",
    name: "EAN8",
  },
];

export const DSOK_SUPPLIERS = [
  {
    code: "besteron",
    name: "Besteron",
  },
  {
    code: "fiskalPro",
    name: "FiskalPro",
  },
];

export const DSOK_ORDER_MANAGEMENT_TYPES = [
  {
    company: "None",
    code: "none",
  },
  {
    company: "IKELP",
    code: "ikelp",
    fetchProductsUrl: "https://intesso.ikelp.com/api/kiosk/menuimport",
  },
  {
    company: "n8n workflow",
    code: "n8n",
    fetchProductsUrl: "",
  },
];

export const DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const DSOK_MENU_TYPES = ["TAKEAWAY", "DINE_IN"];

export const DSOK_REPORTS_TYPES = ["daily", "monthly"];

export const DSOK_TRANSACTION_TYPES = [
  { name: "transactionLive", code: "live" },
  { name: "transactionTest", code: "test" },
];

export const DSOK_EKASA_TYPES = [
  { name: "none", code: "none" },
  { name: "portos", code: "portos_sk" },
  { name: "fiskalPro", code: "fiskalPro_sk" },
];

export const BE_DOMAINS = [
  "https://be.gastrokiosk.online",
  "https://staging.gastrokiosk.online",
  "https://be-gc.gastrokiosk.online",
];

export const DSOK_FE_DOMAIN = "gastrokiosk.eu";

export const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const DSOK_ORDER_STATUSSES = [
  "sent",
  "created",
  "payment failed",
  "complete",
];

export const DSOK_DEVICE_SERVER_PORT_START = 12000;

export const VAT_RATES = [0, 10, 20];
