import React, { useState } from "react";
import { validateToken } from "app/services/api/partner.js";
import { PartnerAuthContext } from "./PartnerAuthContext";

// Create the provider component
export const PartnerAuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
  });

  const validate = async (token) => {
    try {
      const { data } = await validateToken(token);
      localStorage.setItem("PARTNER_TOKEN", token);
      localStorage.setItem("PARTNER_USER", JSON.stringify(data));

      setAuthState({
        isAuthenticated: true,
        user: data,
      });
      return data;
    } catch (error) {
      localStorage.removeItem("PARTNER_TOKEN");
      localStorage.removeItem("PARTNER_USER");
      throw error;
    }
  };

  const token = localStorage.getItem("PARTNER_TOKEN");
  const user = JSON.parse(localStorage.getItem("PARTNER_USER") || "{}");

  return (
    <PartnerAuthContext.Provider
      value={{ ...authState, validate, token, user }}
    >
      {children}
    </PartnerAuthContext.Provider>
  );
};
