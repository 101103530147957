import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Field, useFormikContext } from "formik";
import { Label } from "app/pages/presentation/module/form/types";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import JumboListNoDataPlaceholder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder";
import * as Yup from "yup";
import {
  BE_DOMAINS,
  DSOK_DEVICE_SERVER_PORT_START,
  DSOK_FE_DOMAIN,
  DSOK_SUPPLIERS,
  DSOK_TRANSACTION_TYPES,
  DSOK_EKASA_TYPES,
} from "app/utils/constants/settings";
import { DateTimeField } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { replaceAll } from "app/utils/stringHelpers";
import { Link } from "react-router-dom";
import { getDeviceStatus as getPartnerDeviceStatus } from "app/services/api/partner";
import { getDeviceStatus } from "app/services/api/management";

const FIELD_NAME = "DEVICES_FIELD";

export const getDevicesFieldSchema = (devices) => {
  const schema = {};
  const deviceKeys = Object.keys(devices || {});
  if (!deviceKeys?.length) {
    return schema;
  }
  deviceKeys.forEach((device) => {
    schema[device] = {
      uuid: "",
      currency: "",
      testMode: "",
      maintenance: "",
      forceMaintenance: "",
      pos: {
        supplier: "",
        ip: "",
        localPort: "",
        serverPort: "",
        username: "",
        password: "",
        printReceipt: "",
        poiId: "",
        serialNumber: "",
      },
      ekasa: {
        ip: "",
        localPort: "",
        serverPort: "",
        headerText: "",
        footerText: "",
        dic: "",
        cashRegisterCode: "",
        customer: {
          id: "",
          type: "",
        },
        print: {
          options: {
            to: "m.valasek@intesso.sk",
          },
          printerName: "",
        },
        transactionType: "",
        eKasaType: "none",
      },
      generalServer: {
        enableGeneralServer: "",
        localPort: "",
        serverPort: "",
      },
      location: "",
      orderProcess: {
        enablePosPayment: "",
        enablePrintReceipt: "",
      },
    };
  });
  return schema;
};

export const getDevicesFieldValidationSchema = (t) => ({
  uuid: Yup.string().required(t("messages.checkRequiredFields")),
  currency: Yup.string().required(t("messages.checkRequiredFields")),
  pos: Yup.object().shape({
    supplier: Yup.string().required(t("messages.checkRequiredFields")),
    ip: Yup.string().required(t("messages.checkRequiredFields")),
    localPort: Yup.string().required(t("messages.checkRequiredFields")),
    serverPort: Yup.string().required(t("messages.checkRequiredFields")),
  }),
  ekasa: Yup.object().shape({
    headerText: Yup.string(),
    footerText: Yup.string(),
    dic: Yup.string().required(t("messages.checkRequiredFields")),
    cashRegisterCode: Yup.string().required(t("messages.checkRequiredFields")),
    customer: Yup.object().shape({
      id: Yup.string().required(t("messages.checkRequiredFields")),
      type: Yup.string().required(t("messages.checkRequiredFields")),
    }),
    print: Yup.object().shape({
      options: Yup.object().shape({
        to: Yup.string().required(t("messages.checkRequiredFields")),
      }),
      printerName: Yup.string().required(t("messages.checkRequiredFields")),
    }),
    transactionType: Yup.string().required(t("messages.checkRequiredFields")),
    eKasaType: Yup.string().required(t("messages.checkRequiredFields")),
  }),
  location: Yup.string().required(t("messages.checkRequiredFields")),
});

const DevicesField = ({
  handleChange,
  expanded,
  isAdmin,
  isPartnerAdmin,
  token,
  customerUrl,
  isPartner,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const [selectedDevice, setSelectedDevice] = useState("1");
  const [manuelChecking, setManuelChecking] = useState(false);
  const { showDialog, hideDialog } = useJumboDialog();
  const { enqueueSnackbar } = useSnackbar();

  const deviceKeys = Object.keys(values.customer.devices || {});

  const handleManuelCheckDevice = async (deviceId) => {
    setManuelChecking(true);
    const handler = isPartnerAdmin ? getPartnerDeviceStatus : getDeviceStatus;
    const status = await handler(deviceId, customerUrl, token);

    const ekasaMessage = `${t("pages.dsokDetails.ekasa")}: ${
      status.ekasaStatus
        ? t("pages.dsokDetails.deviceOK")
        : t("pages.dsokDetails.deviceUnavailable")
    }`;

    const posMessage = `${t("pages.dsokDetails.pos")}: ${
      status.posStatus
        ? t("pages.dsokDetails.deviceOK")
        : t("pages.dsokDetails.deviceUnavailable")
    }`;
    enqueueSnackbar(ekasaMessage, {
      variant: status.ekasaStatus ? "success" : "error",
    });
    enqueueSnackbar(posMessage, {
      variant: status.posStatus ? "success" : "error",
    });
    setManuelChecking(false);
  };

  const handleAddDevice = () => {
    const posPort =
      DSOK_DEVICE_SERVER_PORT_START + (+deviceKeys.length - 1) * 16;
    const ekasaPort = posPort + 1;
    const generalServerPort = ekasaPort + 1;
    setFieldValue("customer.devices", {
      ...values.customer.devices,
      [deviceKeys.length + 1]: {
        location: "1",
        pos: { supplier: DSOK_SUPPLIERS[0].code, serverPort: posPort },
        ekasa: {
          print: { options: { to: "m.valasek@intesso.sk" } },
          serverPort: ekasaPort,
        },
        generalServer: { serverPort: generalServerPort },
      },
    });
  };

  const handleDeleteDevice = (device) => {
    const newDevices = {};
    let deleted = 0;
    deviceKeys.forEach((key, index) => {
      if (key === device) {
        deleted++;
        return;
      }
      newDevices[index + 1 - deleted] = values.customer.devices[key];
    });
    setFieldValue("customer.devices", newDevices);
    setSelectedDevice(deviceKeys[0]);
    hideDialog();
  };

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.devices")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          spacing={1}
          px={2}
        >
          <TabContext value={selectedDevice}>
            <Stack width="100%">
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <TabList onChange={(_, val) => setSelectedDevice(val)}>
                  {deviceKeys.map((item) => {
                    let demoDeviceText = "";
                    if (values.customer.devices[item].demoMode) {
                      demoDeviceText = " - DEMO";
                    }
                    return (
                      <Tab
                        key={`tab_device_${item}`}
                        label={
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            {`${item} ` + demoDeviceText}
                            {isAdmin && (
                              <IconButton
                                onClick={() =>
                                  showDialog({
                                    variant: "confirm",
                                    title: t(
                                      "widgets.confirmDialog.areYouSure"
                                    ),
                                    onYes: () => handleDeleteDevice(item),
                                    onNo: () => hideDialog(),
                                  })
                                }
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Stack>
                        }
                        value={item}
                      />
                    );
                  })}
                </TabList>
                {isAdmin && (
                  <IconButton onClick={handleAddDevice}>
                    <AddCircleIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
              {!deviceKeys?.length ? (
                <JumboListNoDataPlaceholder />
              ) : (
                deviceKeys.map((device) => {
                  let isFrontendDemoDevice = false;
                  if (values.customer.devices[device].demoMode) {
                    isFrontendDemoDevice = true;
                  }
                  const customerId = values.customer.customerId;
                  const customerCode = values.customer.code;
                  const locationId = values.customer.devices[device].location;
                  const locationCode =
                    values.customer.locations[locationId].code;
                  const deviceUUID = values.customer.devices[device].uuid;
                  debugger;
                  const env =
                    values.customer.beDomain ===
                    "https://staging.gastrokiosk.online"
                      ? "stage"
                      : "prod";
                  //compose preview link based on selected frontend domain
                  let previewButtonLink = `https://${replaceAll(
                    values.customer.code,
                    "-",
                    ""
                  )}.${DSOK_FE_DOMAIN}/?kioskId=${
                    values.customer.devices[device]?.uuid
                  }`;
                  let callScreenUrl = null;
                  if (values?.customer?.frontendType === "easyPay") {
                    previewButtonLink = `https://easypay.gastrokiosk.online/?customerId=${customerId}&deviceId=${deviceUUID}`;
                    callScreenUrl = `${window.location.origin}/easy-pay/call-screen/${customerCode}/${locationCode}/${env}/sk-SK/15000`;
                  }
                  return (
                    <TabPanel
                      key={`tab_panel_device_${device}`}
                      value={device}
                      sx={{
                        pb: 1,
                        pr: 0,
                        pl: 0,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <FormControlLabel
                              control={
                                <Field
                                  name={`customer.devices.${device}.forceMaintenance`}
                                  component={Switch}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `customer.devices.${device}.forceMaintenance`,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={
                                <Label
                                  text={t("pages.dsokDetails.forceMaintenance")}
                                  helpText={t(
                                    "pages.dsokDetails.forceMaintenanceDeviceHelp"
                                  )}
                                  sx={{ color: "text.primary" }}
                                  required={false}
                                />
                              }
                              checked={
                                values.customer.devices[device].forceMaintenance
                              }
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <FormControlLabel
                              control={
                                <Field
                                  name={`customer.devices.${device}.maintenance`}
                                  component={Switch}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `customer.devices.${device}.maintenance`,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={
                                <Label
                                  text={t(
                                    "pages.dsokDetails.enableMaintenance"
                                  )}
                                  helpText={t(
                                    "pages.dsokDetails.enableMaintenanceDeviceHelp"
                                  )}
                                  sx={{ color: "text.primary" }}
                                  required={false}
                                />
                              }
                              checked={
                                values.customer.devices[device].maintenance
                              }
                            />
                          </Div>
                        </Grid>
                        {(isAdmin || isPartnerAdmin) && (
                          <>
                            <Grid item xs={12} md={2}>
                              <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                <Link
                                  to={previewButtonLink}
                                  style={{
                                    textDecoration: "none",
                                    alignSelf: "center",
                                  }}
                                  target="_blank"
                                >
                                  <Button
                                    variant="contained"
                                    startIcon={<OpenInNewIcon />}
                                    sx={{
                                      boxShadow: "none",
                                      height: "38px",
                                    }}
                                    size="small"
                                  >
                                    {t("pages.dsokDetails.openFrontend")}
                                  </Button>
                                </Link>
                              </Div>
                            </Grid>
                          </>
                        )}
                        {(isAdmin || isPartnerAdmin) &&
                          callScreenUrl !== null && (
                            <>
                              <Grid item xs={12} md={2}>
                                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                  <Link
                                    to={callScreenUrl}
                                    style={{
                                      textDecoration: "none",
                                      alignSelf: "center",
                                    }}
                                    target="_blank"
                                  >
                                    <Button
                                      variant="contained"
                                      startIcon={<OpenInNewIcon />}
                                      sx={{
                                        boxShadow: "none",
                                        height: "38px",
                                      }}
                                      size="small"
                                    >
                                      {t("pages.dsokDetails.callScreenOpen")}
                                    </Button>
                                  </Link>
                                </Div>
                              </Grid>
                            </>
                          )}
                        {(isAdmin || isPartnerAdmin) &&
                          !isFrontendDemoDevice && (
                            <Grid item xs={12} md={2}>
                              <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    boxShadow: "none",
                                    height: "38px",
                                  }}
                                  disabled={manuelChecking}
                                  onClick={() =>
                                    handleManuelCheckDevice(device)
                                  }
                                >
                                  {t("pages.dsokDetails.manuelCheck")}
                                </Button>
                              </Div>
                            </Grid>
                          )}
                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimeField
                                label={t("pages.dsokDetails.lastHeartbeat")}
                                name={`customer.devices.${device}.lastHeartbeat`}
                                readOnly
                                slotProps={{
                                  textField: {
                                    InputLabelProps: { shrink: true },
                                    placeholder: "",
                                  },
                                }}
                                value={
                                  values.customer.devices[device]
                                    .lastHeartbeat &&
                                  parseISO(
                                    values.customer.devices[device]
                                      .lastHeartbeat
                                  )
                                }
                              />
                            </LocalizationProvider>
                          </Div>
                        </Grid>
                        {isPartner && (
                          <>
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                                <JumboTextField
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.deviceName`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.deviceName")}
                                      required
                                    />
                                  }
                                ></JumboTextField>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {(isAdmin || isPartner) && (
                          <>
                            {!isPartnerAdmin && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                    <FormControlLabel
                                      control={
                                        <Field
                                          name={`customer.devices.${device}.testMode`}
                                          component={Switch}
                                          onChange={(e) =>
                                            setFieldValue(
                                              `customer.devices.${device}.testMode`,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label={
                                        <Label
                                          text={t("pages.dsokDetails.testMode")}
                                          color="primary"
                                          helpText={t(
                                            "pages.dsokDetails.testModeHelp"
                                          )}
                                        />
                                      }
                                      checked={
                                        values.customer.devices[device].testMode
                                      }
                                    />
                                  </Div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                    {/* frontend demo device */}
                                    <FormControlLabel
                                      control={
                                        <Field
                                          name={`customer.devices.${device}.demoMode`}
                                          component={Switch}
                                          onChange={(e) =>
                                            setFieldValue(
                                              `customer.devices.${device}.demoMode`,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label={
                                        <Label
                                          text={t("pages.dsokDetails.demoMode")}
                                          color="primary"
                                          helpText={t(
                                            "pages.dsokDetails.demoModeHelp"
                                          )}
                                        />
                                      }
                                      checked={
                                        values.customer.devices[device].demoMode
                                      }
                                    />
                                  </Div>
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                        {isAdmin && (
                          <>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                <FormControlLabel
                                  control={
                                    <Field
                                      name={`customer.devices.${device}.orderProcess.enablePrintReceipt`}
                                      component={Switch}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `customer.devices.${device}.orderProcess.enablePrintReceipt`,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <Label
                                      text={t(
                                        "pages.dsokDetails.enablePrintReceipt"
                                      )}
                                      color="primary"
                                      helpText={t(
                                        "pages.dsokDetails.enablePrintReceiptHelp"
                                      )}
                                    />
                                  }
                                  checked={
                                    values.customer.devices[device].orderProcess
                                      ?.enablePrintReceipt
                                  }
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                                <JumboTextField
                                  required
                                  select
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.beDomain`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.beDomain")}
                                      required
                                    />
                                  }
                                >
                                  {BE_DOMAINS.map((domain) => {
                                    return (
                                      <MenuItem key={domain} value={domain}>
                                        {domain}
                                      </MenuItem>
                                    );
                                  })}
                                </JumboTextField>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                                <JumboTextField
                                  required
                                  fullWidth
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.deviceName`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.deviceName")}
                                      required
                                    />
                                  }
                                ></JumboTextField>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                                <JumboTextField
                                  required
                                  fullWidth
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.serialNumber`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.serialNumber")}
                                      required
                                    />
                                  }
                                ></JumboTextField>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                <FormControlLabel
                                  control={
                                    <Field
                                      name={`customer.devices.${device}.restartApp`}
                                      component={Switch}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `customer.devices.${device}.restartApp`,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.restartApp")}
                                      helpText={t(
                                        "pages.dsokDetails.restartAppHelp"
                                      )}
                                      sx={{ color: "text.primary" }}
                                      required={false}
                                    />
                                  }
                                  checked={
                                    values.customer.devices[device].restartApp
                                  }
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                                <JumboTextField
                                  required
                                  select
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.location`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.location")}
                                      required
                                    />
                                  }
                                >
                                  {Object.keys(
                                    values?.customer.locations || {}
                                  ).map((location) => {
                                    return (
                                      <MenuItem key={location} value={location}>
                                        {
                                          values?.customer.locations[location]
                                            .code
                                        }
                                      </MenuItem>
                                    );
                                  })}
                                </JumboTextField>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.devices.${device}.uuid`}
                                  label={t("pages.dsokDetails.uuid")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.devices.${device}.currency`}
                                  label={t("pages.dsokDetails.currency")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                {t("pages.dsokDetails.pos")}
                              </Typography>
                              <Divider />
                            </Grid>
                            {isAdmin && (
                              <Grid item xs={12} md={4}>
                                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        name={`customer.devices.${device}.orderProcess.enablePosPayment`}
                                        component={Switch}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `customer.devices.${device}.orderProcess.enablePosPayment`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <Label
                                        text={t(
                                          "pages.dsokDetails.enablePosPayment"
                                        )}
                                        color="primary"
                                        helpText={t(
                                          "pages.dsokDetails.enablePosPaymentHelp"
                                        )}
                                      />
                                    }
                                    checked={
                                      values.customer.devices[device]
                                        .orderProcess?.enablePosPayment
                                    }
                                  />
                                </Div>
                              </Grid>
                            )}
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                                <JumboTextField
                                  required
                                  select
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.pos.supplier`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.supplier")}
                                      required
                                    />
                                  }
                                >
                                  {DSOK_SUPPLIERS.map(({ code, name }) => {
                                    return (
                                      <MenuItem key={code} value={code}>
                                        {name}
                                      </MenuItem>
                                    );
                                  })}
                                </JumboTextField>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                <FormControlLabel
                                  control={
                                    <Field
                                      name={`customer.devices.${device}.pos.printReceipt`}
                                      component={Switch}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `customer.devices.${device}.pos.printReceipt`,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={t("pages.dsokDetails.printReceipt")}
                                  checked={
                                    values.customer.devices[device].pos
                                      ?.printReceipt
                                  }
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  required
                                  fullWidth
                                  name={`customer.devices.${device}.pos.ip`}
                                  label={t("pages.dsokDetails.ip")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.devices.${device}.pos.localPort`}
                                  label={t("pages.dsokDetails.localPort")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  required
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  name={`customer.devices.${device}.pos.serverPort`}
                                  label={t("pages.dsokDetails.serverPort")}
                                />
                              </Div>
                            </Grid>
                            {values.customer.devices[device].pos?.supplier ===
                              "besteron" && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <Div sx={{ mt: 1, mb: 1 }}>
                                    <JumboTextField
                                      fullWidth
                                      required
                                      name={`customer.devices.${device}.pos.poiId`}
                                      label={t("pages.dsokDetails.poiId")}
                                    />
                                  </Div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Div sx={{ mt: 1, mb: 1 }}>
                                    <JumboTextField
                                      fullWidth
                                      name={`customer.devices.${device}.pos.username`}
                                      label={t("pages.login.username")}
                                    />
                                  </Div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Div sx={{ mt: 1, mb: 1 }}>
                                    <JumboTextField
                                      fullWidth
                                      name={`customer.devices.${device}.pos.password`}
                                      label={t("pages.login.password")}
                                    />
                                  </Div>
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  name={`customer.devices.${device}.pos.serialNumber`}
                                  label={t("pages.dsokDetails.posSerialNumber")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                {t("pages.dsokDetails.ekasa")}
                              </Typography>
                              <Divider />
                            </Grid>
                            {isAdmin && (
                              <Grid item xs={12} md={4}>
                                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        name={`customer.devices.${device}.orderProcess.enableEkasa`}
                                        component={Switch}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `customer.devices.${device}.orderProcess.enableEkasa`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <Label
                                        text={t(
                                          "pages.dsokDetails.enableEkasa"
                                        )}
                                        color="primary"
                                        helpText={t(
                                          "pages.dsokDetails.enableEkasaHelp"
                                        )}
                                      />
                                    }
                                    checked={
                                      values.customer.devices[device]
                                        .orderProcess?.enableEkasa
                                    }
                                  />
                                </Div>
                              </Grid>
                            )}
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  select
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.ekasa.eKasaType`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.eKasaType")}
                                      required
                                    />
                                  }
                                >
                                  {DSOK_EKASA_TYPES.map(({ code, name }) => {
                                    return (
                                      <MenuItem key={code} value={code}>
                                        {t(`pages.dsokDetails.${name}`)}
                                      </MenuItem>
                                    );
                                  })}
                                </JumboTextField>
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  name={`customer.devices.${device}.ekasa.headerText`}
                                  label={t("pages.dsokDetails.headerText")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  name={`customer.devices.${device}.ekasa.footerText`}
                                  label={t("pages.dsokDetails.footerText")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  name={`customer.devices.${device}.ekasa.ip`}
                                  label={t("pages.dsokDetails.ip")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  name={`customer.devices.${device}.ekasa.localPort`}
                                  label={t("pages.dsokDetails.localPort")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  name={`customer.devices.${device}.ekasa.serverPort`}
                                  label={t("pages.dsokDetails.serverPort")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.devices.${device}.ekasa.dic`}
                                  label={t("pages.dsokDetails.dic")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.devices.${device}.ekasa.cashRegisterCode`}
                                  label={t(
                                    "pages.dsokDetails.cashRegisterCode"
                                  )}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.devices.${device}.ekasa.customer.id`}
                                  label={t("pages.dsokDetails.customerId")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.devices.${device}.ekasa.customer.type`}
                                  label={t("pages.dsokDetails.customerType")}
                                />
                              </Div>
                            </Grid>
                            {/* <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.devices.${device}.ekasa.print.printerName`}
                                  label={t('pages.dsokDetails.printerName')}
                                />
                              </Div>
                            </Grid> */}
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  select
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.ekasa.print.printerName`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.printerName")}
                                      required
                                    />
                                  }
                                >
                                  <MenuItem key="1" value="email">
                                    email
                                  </MenuItem>
                                  <MenuItem key="2" value="pos">
                                    pos
                                  </MenuItem>
                                </JumboTextField>
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  select
                                  InputLabelProps={{ required: false }}
                                  name={`customer.devices.${device}.ekasa.transactionType`}
                                  label={
                                    <Label
                                      text={t(
                                        "pages.dsokDetails.transactionType"
                                      )}
                                      helpText={t(
                                        "pages.dsokDetails.transactionTypeHelp"
                                      )}
                                      required
                                    />
                                  }
                                >
                                  {DSOK_TRANSACTION_TYPES.map(
                                    ({ code, name }) => {
                                      return (
                                        <MenuItem key={code} value={code}>
                                          {t(`pages.dsokDetails.${name}`)}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </JumboTextField>
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                                <JumboTextField
                                  type="string"
                                  InputLabelProps={{ required: false }}
                                  required
                                  fullWidth
                                  name={`customer.devices.${device}.ekasa.print.paperState`}
                                  label={
                                    <Label
                                      text={t("pages.dsokDetails.paperState")}
                                      required
                                    />
                                  }
                                  InputProps={{
                                    value:
                                      values.customer.devices[device].ekasa
                                        .print.paperState,
                                    readOnly: true,
                                    onChange: (e) =>
                                      setFieldValue(
                                        `customer.devices.${device}.ekasa.print.paperState`,
                                        e.target.value
                                      ),
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                {t("pages.dsokDetails.generalServer")}
                              </Typography>
                              <Divider />
                            </Grid>
                            {isAdmin && (
                              <Grid item xs={12} md={4}>
                                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        name={`customer.devices.${device}.generalServer.enableGeneralServer`}
                                        component={Switch}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `customer.devices.${device}.generalServer.enableGeneralServer`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <Label
                                        text={t(
                                          "pages.dsokDetails.enableGeneralServer"
                                        )}
                                        color="primary"
                                        helpText={t(
                                          "pages.dsokDetails.enableGeneralServerHelp"
                                        )}
                                      />
                                    }
                                    checked={
                                      values.customer.devices[device]
                                        .generalServer?.enableGeneralServer
                                    }
                                  />
                                </Div>
                              </Grid>
                            )}
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  name={`customer.devices.${device}.generalServer.localPort`}
                                  label={t("pages.dsokDetails.localPort")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  name={`customer.devices.${device}.generalServer.serverPort`}
                                  label={t("pages.dsokDetails.serverPort")}
                                />
                              </Div>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </TabPanel>
                  );
                })
              )}
            </Stack>
          </TabContext>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default DevicesField;
